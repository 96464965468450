import CircularProgress from "@material-ui/core/CircularProgress";
import React, {useEffect} from "react";
import Typography from "@material-ui/core/Typography";

const EHealthCallback = () => {
    const urlParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        window.location.href = `https://clinic.aipromis.com.ua/callback?hash=${urlParams.get('code')}`
    }, [])


    return <div style={{
        width: '98vw', height: '98vh', display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        <div>
            <Typography variant='h3' align='center' style={{color:'#3F51B5'}}>Зачекайте будь ласка!</Typography>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <CircularProgress size={200}/>
            </div>
        </div>
    </div>
}

export default EHealthCallback;